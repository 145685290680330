import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CardColumns from 'react-bootstrap/CardColumns';
import Button from 'react-bootstrap/Button';
import PostCard from './PostCard';

class PostSection extends React.Component {
  static defaultProps = {
    posts: [],
    title: '',
    limit: 12,
    showLoadMore: true,
    loadMoreTitle: 'Load More',
    perPageLimit: 12,
  };

  state = {
    limit: this.props.limit,
  };

  increaseLimit = () =>
    this.setState((prevState) => ({
      limit: prevState.limit + this.props.perPageLimit,
    }));

  render() {
    const { posts, title, showLoadMore, loadMoreTitle } = this.props;
    const { limit } = this.state;
    const visiblePosts = posts.slice(0, limit || posts.length);

    return (
      <Container>
        <Row>
          {title && (
            <Col xs={12}>
              <h2>{title}</h2>
            </Col>
          )}
          <Col xs={12}>
            {!!visiblePosts.length && (
              <CardColumns>
                {visiblePosts.map((post, index) => (
                  <PostCard key={post.title + index} {...post} />
                ))}
              </CardColumns>
            )}
          </Col>
          <Col xs={12} className="text-center">
            {showLoadMore && visiblePosts.length < posts.length && (
              <Button variant="link" onClick={this.increaseLimit}>
                {loadMoreTitle}
              </Button>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default PostSection;
