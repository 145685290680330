import React from 'react';
import { Link } from 'gatsby';
import Card from 'react-bootstrap/Card';

const PostCard = ({
  featuredImage,
  title,
  excerpt,
  slug,
  categories = [],
  className = '',
  ...props
}) => (
  <Card>
    {featuredImage && <Card.Img variant="top" src={featuredImage} />}
    <Card.Body>
      {title && <Card.Title>{title}</Card.Title>}
      <small>{categories && categories.map((cat) => cat.category).join(', ')}</small>
      {excerpt && <Card.Text>{excerpt}</Card.Text>}
      <Link to={slug} className="stretched-link" />
    </Card.Body>
  </Card>
);

export default PostCard;
