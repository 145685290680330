import React from 'react';
import { Link } from 'gatsby';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import BlogSearch from './BlogSearch';

const PostCategoriesNav = ({ categories, enableSearch }) => (
  <Navbar className="w-100">
    <Nav onSelect={(selectedKey) => alert(`selected ${selectedKey}`)} className="mr-auto">
      <Nav.Item>
        <Link exact="true" className="nav-link" to="/blog/">
          All
        </Link>
      </Nav.Item>
      {categories.map((category, index) => (
        <Nav.Item key={category.title + index}>
          <Link exact="true" className="nav-link" to={category.slug}>
            {category.title}
          </Link>
        </Nav.Item>
      ))}
    </Nav>
    {enableSearch && <BlogSearch />}
  </Navbar>
);

export default PostCategoriesNav;
