import React from 'react';
import { navigate } from 'gatsby';
import { Location } from '@reach/router';
import qs from 'qs';
import Form from 'react-bootstrap/Form';

export default ({ pageCount }) => {
  return (
    <Location>
      {({ location }) => {
        const search = qs.parse(location.search.replace('?', ''));

        return (
          <Form inline>
            <Form.Control
              type="text"
              value={search.s || ''}
              className="shadow-sm"
              placeholder="Search a blog post..."
              onChange={(e) => {
                let search = {};
                search.s = e.target.value;
                search = qs.stringify(search);

                const url = location.href.replace(location.origin, '').replace(location.search, '');

                navigate(`${url}?${search}`);
              }}
            />
          </Form>
        );
      }}
    </Location>
  );
};
