import React from 'react';
import { graphql } from 'gatsby';
import { Location } from '@reach/router';
import qs from 'qs';
import styled from 'styled-components';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import PageHeader from '../components/PageHeader';
import PostSection from '../components/PostSection';
import PostCategoriesNav from '../components/PostCategoriesNav';
import Layout from '../components/Layout';

/**
 * Filter posts by date. Feature dates will be fitered
 * When used, make sure you run a cronejob each day to show schaduled content. See docs
 *
 * @param {posts} object
 */
export const byDate = (posts) => {
  const now = Date.now();
  return posts.filter((post) => Date.parse(post.date) <= now);
};

/**
 * filter posts by category.
 *
 * @param {posts} object
 * @param {title} string
 * @param {contentType} string
 */
export const byCategory = (posts, title, contentType) => {
  const isCategory = contentType === 'postCategories';
  const byCategory = (post) =>
    post.categories && post.categories.filter((cat) => cat.category === title).length;
  return isCategory ? posts.filter(byCategory) : posts;
};

const StyledMain = styled.main`
  .card {
    background-color: ${(props) => props.theme.backgroundColor};
    color: ${(props) => props.theme.textColor};
    min-height: 132px;
    min-width: 160px;
  }

  .card:hover {
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }

  .nav-link {
    color: ${(props) => props.theme.textColor} !important;
  }

  .form-control {
    background-color: ${(props) => props.theme.backgroundColor};
    color: ${(props) => props.theme.textColor} !important;
  }
`;

// Export Template for use in CMS preview
export const BlogIndexTemplate = ({
  title,
  subtitle,
  posts = [],
  postCategories = [],
  enableSearch = true,
  contentType,
}) => (
  <Location>
    {({ location }) => {
      let filteredPosts =
        posts && !!posts.length ? byCategory(byDate(posts), title, contentType) : [];

      let queryObj = location.search.replace('?', '');
      queryObj = qs.parse(queryObj);

      if (enableSearch && queryObj.s) {
        const searchTerm = queryObj.s.toLowerCase();
        filteredPosts = filteredPosts.filter((post) =>
          post.frontmatter.title.toLowerCase().includes(searchTerm)
        );
      }

      return (
        <StyledMain className="Blog">
          <PageHeader title={title} subtitle={subtitle} />

          {!!postCategories.length && (
            <Container className="mb-5">
              <Row>
                <PostCategoriesNav enableSearch categories={postCategories} />
              </Row>
            </Container>
          )}

          {!!posts.length && (
            <Container>
              <Row>
                <PostSection posts={filteredPosts} />
              </Row>
            </Container>
          )}
        </StyledMain>
      );
    }}
  </Location>
);

// Export Default BlogIndex for front-end
const BlogIndex = ({ data: { page, posts, postCategories } }) => (
  <Layout meta={page.frontmatter.meta || false} title={page.frontmatter.title || false}>
    <BlogIndexTemplate
      {...page}
      {...page.fields}
      {...page.frontmatter}
      posts={posts.edges.map((post) => ({
        ...post.node,
        ...post.node.frontmatter,
        ...post.node.fields,
      }))}
      postCategories={postCategories.edges.map((post) => ({
        ...post.node,
        ...post.node.frontmatter,
        ...post.node.fields,
      }))}
    />
  </Layout>
);

export default BlogIndex;

export const pageQuery = graphql`
  ## Query for BlogIndex data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query BlogIndex($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      fields {
        contentType
      }
      frontmatter {
        title
        excerpt
        template
        subtitle
      }
    }

    posts: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "posts" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date
            categories {
              category
            }
            featuredImage
          }
        }
      }
    }
    postCategories: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "postCategories" } } }
      sort: { order: ASC, fields: [frontmatter___title] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
